<template>
  <div class="container">
    <div class="ui-border-line"></div>
    <div class="list-option">
      <div @click="() => $refs.SortModal.show()" class="ui-btn">
        {{sortArr[formData.sort]}}
        <icon class="icon" src="img/common/tab-unfold.png" width="1.2rem" height="5.5rem" :cover="false" />
      </div>
    </div>

    <modal
      ref="SortModal"
      :show-cb="() => modalData.sort = formData.sort"
      :confirm-cb="(modal) => {
        formData.sort = modalData.sort
        $router.replace({ query: { sort: modalData.sort } })
        modal.hide()
      }"
    >
      <ul slot="body">
        <li
          class="ui-btn text-center"
          v-for="(row, key) in sortArr"
          :key="key"
          @click="modalData.sort = key"
          :class="{ on: modalData.sort === key }"
        >
          {{row}}
          <div class="ui-border-line"></div>
        </li>
      </ul>
    </modal>

    <div
      ref="container"
      class="scroll-container"
      @scroll="e => getArtistDataScroll(e)"
    >
      <img
        :src="$lib.imgUrl('img/recruiter/order-description.png')"
        width="100%"
      >
      <div class="ui-border-line ui-h-0 ui-mb-3"></div>
      <ul class="artist-list" v-if="!init || artistData.length">
        <template v-for="(row, key) in artistData">
          <a-item :key="key" :row="row" no-search-artist-by-tag />
          <div :key="`${key}:border`" class="ui-border-line" />
        </template>
      </ul>
      <blank-search v-else text="지원자가 없습니다." />
    </div>
  </div>
</template>

<script>
import AItem from '@/components/artist/AItem'
import BlankSearch from '@/components/blank/BlankList'
import Icon from '@/components/common/Icon'
import Modal from '@/components/common/Modal'

export default {
  name: 'Recruiter',
  components: {
    AItem,
    BlankSearch,
    Icon,
    Modal,
  },
  data() {
    return {
      init: false,
      sortArr: {
        proOrder: '최근 지원 순',
        lowPrice: '낮은 금액 순',
        order: '결제 많은 순',
        review: '리뷰 많은 순',
        fav: '찜한 전문가만 보기',
      },
      artistData: [],
      formData: {
        sort: this.$route.query.sort || 'proOrder',
      },
      modalData: {
        sort: this.$route.query.sort || 'proOrder',
      },
      offset: {
        artistData: 0,
      },
      que: {
        artistData: false,
      },
      scroll: {
        lastY: 0,
      },
      memo: {
        offerUid: null,
      },
    }
  },
  computed: {
    offerUid() {
      return this.$route.params.offerUid
    },
    isRecruiterPage() {
      return this.$route.name === 'Recruiter'
    },
  },
  watch: {
    '$route.query.sort'() {
      if (this.isRecruiterPage) {
        this.getArtistData(true)
      }
    },
  },
  created() {},
  mounted() {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let reset = false

      if (from.name === 'ClientMyOffer' || vm.memo.offerUid !== vm.offerUid) {
        reset = true
      }

      if (reset) {
        vm.formData.sort = vm.$route.query.sort || 'proOrder'
        vm.formData.modalData = vm.$route.query.sort || 'proOrder'
        vm.getArtistData(true)
        vm.memo.offerUid = vm.offerUid
        vm.scroll.lastY = 0
      }

      vm.$refs.container.scrollTop = vm.scroll.lastY
    })
  },
  beforeRouteLeave (to, from, next) {
    setTimeout(() => {
      if (this.$store.state.history.back) {
        next('/client/my-offer')
      } else {
        this.scroll.lastY = this.$refs.container.scrollTop
        next()
      }
    })
  },
  methods: {
    getArtistData(init) {
      if (this.que.artistData) return
      this.que.artistData = true

      if (init) {
        this.init = true
        this.offset.artistData = 0
        this.artistData = []
      }

      const req = {
        method: 'post',
        url: `/artist/list/all/${this.offset.artistData}/20/${this.formData.sort}`,
        data: {
          offerUid: this.offerUid,
          genre: null,
          location: null,
          priceEnd: null,
          priceStart: null,
          artistCheck: null,
        },
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            let artistData = data.response.artistData || []
            this.artistData = this.artistData.concat(artistData)
            this.offset.artistData += artistData.length
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
        .then(() => {
          this.init = true
          setTimeout(() => {
            this.que.artistData = false
          }, 1000)
        })
    },
    getArtistDataScroll(e) {
      if (['Recruiter'].indexOf(this.$route.name) === -1) return

      if ((e?.target?.scrollHeight || 0) <= (e?.target?.scrollTop || 0) + window.innerHeight) {
        this.getArtistData()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#page > .container {
  display: flex;
  flex-direction: column;
  padding: 0;

  .list-option {
    overflow: hidden;

    > div {
      float: right;
      width: 50%;
      text-align: left;
      color: #919191;
      color: #000;
      font-size: 1.8rem;
      line-height: 5.5rem;
      padding: 0 3rem;

      &:nth-child(2) {
        border-right: 1px solid #ececec;
      }

      .icon {
        float: right;
      }
    }
  }

  .scroll-container {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    .artist-list {
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }
}
</style>
